import React, {useEffect, useState} from 'react'

import {Col, Row} from 'antd'
import {Translate} from 'react-localize-redux'
import {connect} from 'react-redux'

import LocaleString from '../../infra/constants/LocaleString'
import {AddAllItemsToBag} from '../../infra/requests/BagRequests'
import {GetAllProducts} from '../../infra/requests/ProductRequests'
import {GetRenewalDate} from '../../infra/requests/UserRequests'
import BaseLoading from '../../shared/components/loading/BaseLoading'
import PageTopPanel from '../../shared/components/panels/PageTopPanel'
import CreditsProductCard from './components/CreditsProductCard'
import {
  CustomCol,
  OrderBtn,
  PageContainer,
  StatusTag,
  TopBarPanel,
  ValueTag,
  WrapperBtn,
  WrapperBtnMain
} from './components/CreditsProductCardStyle'

const Credits = ({user}) => {
  const [products, setProducts] = useState()
  const [status, setStatus] = useState()
  const [formatedDate, setFormatedDate] = useState()
  const [loading, setLoading] = useState(true)
  const [languageId, setLanguageId] = useState(1)
  const [isHover, setIsHover] = useState(false)

  const handleMouseEnter = () => {
    setIsHover(true)
  }

  const handleMouseLeave = () => {
    setIsHover(false)
  }

  const btnStyle = {
    transition: 'all 0.5s ease',
    color: isHover
      ? user.configuration?.layoutColors?.contentButtonText
      : user.configuration?.layoutColors?.contentText,
    border: `1px solid ${user.configuration?.layoutColors?.contentText}`,
    backgroundColor: isHover
      ? user.configuration?.layoutColors?.contentText
      : 'transparent'
  }

  useEffect(() => {
    const RenewalDate = async () => {
      const {data, success} = await GetRenewalDate()

      if (success) {
        setStatus(data.renewalStatusTranslation)

        const date = new Date(data.renewalDate)

        const day = String(date.getDate()).padStart(2, '0')
        const monthName = date
          .toLocaleString(LocaleString[user.languageId - 1].value, {
            month: 'short'
          })
          .replace('.', '')
        const year = date.getFullYear()

        const newDate = `${day} ${monthName} ${year}`

        setFormatedDate(newDate)

        const result = await GetAllProducts()

        if (result.success) {
          setProducts(result.data.items)
          setLoading(false)
        }
      }
    }
    RenewalDate()

    setLanguageId(user.languageId - 1)
  }, [user.languageId])

  const AddAllToBag = async () => {
    const {success} = await AddAllItemsToBag(user.languageId)

    if (success) {
      window.location.reload(false)
    }
  }

  if (loading) {
    return <BaseLoading />
  }

  return (
    <div>
      <PageTopPanel
        user={user}
        title={<Translate id='CREDIT_TITLE' />}
        subtitle={<Translate id='CREDIT_SUBTITLE' />}
        type='withoutadditional'
      />
      <PageContainer>
        <Row gutter={[0, 0]}>
          <Col xs={2} md={1} lg={4} />
          <Col xs={20} md={22} lg={16}>
            <Row gutter={[0, 0]}>
              <TopBarPanel>
                <Row gutter={[0, 0]}>
                  <Col xs={1} md={1} />
                  <CustomCol xs={22} md={22}>
                    <Row gutter={[0, 0]}>
                      <Col xs={12} md={4} lg={4} xl={2}>
                        <WrapperBtn>
                          <StatusTag>
                            <Translate id='STATUS' />
                          </StatusTag>
                        </WrapperBtn>
                      </Col>
                      <Col xs={12} md={7} lg={7} xl={5}>
                        <WrapperBtn>
                          <ValueTag
                            style={{
                              color:
                                user.configuration?.layoutColors
                                  ?.contentText
                            }}
                          >
                            {status[languageId]?.name}
                          </ValueTag>
                        </WrapperBtn>
                      </Col>

                      <Col xs={12} md={4} lg={4} xl={4}>
                        <WrapperBtn>
                          <StatusTag>
                            <Translate id='STATUS_TAG' />
                          </StatusTag>
                        </WrapperBtn>
                      </Col>
                      <Col xs={12} md={9} lg={9} xl={7}>
                        <WrapperBtn>
                          <ValueTag
                            style={{
                              color:
                                user.configuration?.layoutColors
                                  ?.contentText
                            }}
                          >
                            {formatedDate}
                          </ValueTag>
                        </WrapperBtn>
                      </Col>
                      <Col xs={24} md={24} lg={24} xl={6}>
                        <WrapperBtnMain>
                          <WrapperBtn>
                            <OrderBtn
                              onClick={AddAllToBag}
                              style={btnStyle}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                            >
                              <Translate id='ORDER_BTN' />
                            </OrderBtn>
                          </WrapperBtn>
                        </WrapperBtnMain>
                      </Col>
                    </Row>
                  </CustomCol>
                  <Col xs={1} md={1} />
                </Row>
              </TopBarPanel>
            </Row>
          </Col>
          <Col xs={2} md={1} lg={4} />
        </Row>

        <Row gutter={[0, 0]}>
          <Col xs={1} md={1} lg={4} />
          <Col xs={22} md={22} lg={16}>
            <Row gutter={[20, 30]}>
              {products?.map((item) => (
                <CreditsProductCard
                  user={user}
                  renews={formatedDate}
                  product={item}
                  key={item.productId}
                />
              ))}
            </Row>
          </Col>
          <Col xs={1} md={1} lg={4} />
        </Row>
      </PageContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Credits)
