export default {
  OOPS_PAGE_NOT_FOUND: 'Ups... Seite nicht gefunden!',
  PAGE_NOT_FOUND_DESCRIPTION_1:
    'Wir entschuldigen uns für die Unannehmlichkeiten.',
  PAGE_NOT_FOUND_DESCRIPTION_2:
    'Anscheinend wurde die Seite, die Sie suchen, gelöscht oder existierte nie.',
  BACK_TO_INITIAL_PAGE: 'Zurück zur Startseite',
  PASSWORD_DOESNT_MATCH: 'Das Passwort stimmt nicht überein',
  OUTFIT: 'UNIFORMARTIKEL',
  CREDITS: 'CREDITS',
  FITTING: 'PASSEND ZU',
  MY_ORDERS: 'AUFTRÄGE',
  ADDRESSES: 'ADDRESSBUCH',
  SUPPORT: 'SUPPORT',
  ACCOUNT_SETTINGS: 'KONTOEINSTELLUNGEN',
  MY_BAG: 'Meine Tasche',
  OUTFIT_TITLE: 'Uniformartikel',
  OUTFIT_SUBTITLE:
    'Fügen Sie Ihrem Warenkorb alle Artikel hinzu, die Sie zur Vervollständigung Ihrer Uniform benötigen.',
  CREDIT_TITLE: 'Credits',
  CREDIT_SUBTITLE: 'Überprüfen Sie Ihr Guthaben pro Artikel',
  ORDERS_TITLE: 'Aufträge',
  ORDERS_SUBTITLE: 'aufträge gefunden',
  SUPPORT_TITLE: 'FAQ und Support',
  SUPPORT_SUBTITLE: '',
  FITTING_TITLE: 'Passend zu',
  FITTING_SUBTITLE: 'Überprüfen Sie die Maße der Uniform',
  ADDRESSES_TITLE: 'Adressbuch',
  ADDRESSES_SUBTITLE: 'Verwalten Sie Ihre Adressen',
  PERSONAL_DATA: 'Persönliche daten',
  LOGIN: 'Anmeldung',
  SIGNUP_NEWSLETTER: 'Ich möchte mich für den Skypro-Newsletter anmelden',
  NO_DATA_TO_DISPLAY: 'Keine Daten zum Anzeigen!',
  REVOKE_ACCESS:
    'Widerrufen Sie den Zugriff auf meine Daten, die mit Zustimmung der SKYPRO-Datenschutzrichtlinie erteilt wurden (durch diesen Vorgang werden Sie abgemeldet und die Nutzung des mySkypro-Portals verhindert).',
  ORDER_ALL_OUTFIT: 'BESTELLEN SIE ALLES OUTFIT',
  SAVE: 'SPAREN',
  EDIT: 'BEARBEITEN',
  ADD_NEW_ADDRESS: 'NEUE ADRESSE HINZUFÜGEN',
  CLOSE: 'SCHLIESSE',
  PRIMARY_ADDRESS: 'Bevorzugte adresse',
  LOGOUT: 'AUSLOGGEN',
  EMAIL_USERNAME: 'Email / Nutzername',
  CURRENT_PASSWORD: 'Jetziges Passwort',
  NEW_PASSWORD: 'Neues Kennwort',
  CONFIRM_PASSWORD: 'Bestätige neues Passwort',
  ADDRESS_NAME: 'Addresse name',
  NAME_FOR_DELIVERY: 'Name für die lieferung',
  ADDRESS_LINE_1: 'Adresszeile 1',
  ADDRESS_LINE_2: 'Adresszeile 2',
  ADDRESS_LINE_3: 'Adresszeile 3',
  STREET_NUMBER: 'Hausnummer',
  ZIP_CODE: 'Postleitzahl',
  CITY: 'Stadt',
  REGION: 'Region',
  COUNTRY: 'Land',
  PHONE_NUMBER: 'Telefonnummer',
  VAT_NUMBER: 'Umsatzsteuer-Identifikationsnummer',
  ADD_NEW_ADDRESS_LOWER: 'Neue Adresse hinzufügen',
  USED_CREDITS: 'VERWENDETE CREDITS',
  ITEMS_IN_OUTFIT: 'Artikel in Ihrem Outfit',
  OTHER_ITEMS_FOR_YOU: 'Weitere Artikel für Sie',
  OTHER_ITEMS_DESCRIPTION:
    'Wir haben weitere Artikel gefunden, die Ihre Uniform ergänzen könnten.',
  WELCOME_LOGIN: 'Willkommen zu',
  WELCOME_SUB:
    'Steigern Sie die Effizienz und stärken Sie gleichzeitig Ihre Crew',
  WELCOME_SUB_DEFAULT:
    'Eine einzigartige Mischung aus Know-how und Technologie, die für Ihr Unternehmen die besten Crew-Care-Lösungen der Welt schafft',
  SIGN_IN: 'Anmelden',
  RECOVER: 'Genesen',
  EMAIL_LOGIN: 'Email',
  PASSWORD_LOGIN: 'Passwort',
  LOG_IN: 'Anmeldung',
  REMEMBER_ME: 'Erinnere dich an mich',
  RECOVER_PASSWORD: 'Haben Sie Ihr Passwort vergessen?',
  ACCOUNT: 'konto',
  ORDERS: 'aufträge',
  FITTING_SMALL: 'passend zu',
  HOW_CHANGE_PASSWORD: 'Wie ändere ich mein Passwort?',
  HOW_CHANGE_PASSWORD_ANSWER:
    'Klicken Sie oben rechts auf Ihren Namen oder <a href="/accountsettings">hier</a>, um zu Ihren Kontodefinitionen zu gelangen, wo Sie Ihr Passwort ändern können, um sich bei Ihrem Konto anzumelden.',
  HOW_CHECK_CREDITS: 'Wie überprüfe ich meine Kreditwürdigkeit?',
  HOW_CHECK_CREDITS_ANSWER:
    'Sie können Ihre Credits unter <a href="/credits">Credits</a> überprüfen. Sie finden eine Liste mit allen Artikeln auf Ihrer Uniform und deren Rest- und Gesamtguthaben sowie ggf. dem Verlängerungsdatum.',
  HOW_CHANGE_ADDRESSES: 'Wie ändere/füge ich Adressen und Kontakte hinzu?',
  HOW_CHANGE_ADDRESSES_ANSWER:
    'Gehen Sie zu <a href="/addresses">Adressen</a>, um Adressen und Kontakte zu ändern/hinzufügen. Klicken Sie auf „Bearbeiten“, um die Daten zu ändern, oder auf „Neue Adresse hinzufügen“, um eine zusätzliche Adresse hinzuzufügen. Denken Sie daran, dass Sie Ihre Kontakte aktualisieren sollten, bevor Sie eine Bestellung abschließen.',
  HOW_CHECK_UNIFORM: 'Wie überprüfe ich meine Uniform?',
  HOW_CHECK_UNIFORM_ANSWER:
    'Sie können Ihre Uniformartikel unter <a href="/outfit">Outfit</a> überprüfen. Es werden nur die Artikel angezeigt, aus denen Ihre Uniform besteht.',
  HOW_CHANGE_SIZE: 'Wie ändere ich die Größe meiner Uniform?',
  HOW_CHANGE_SIZE_ANSWER:
    'Planen Sie unter <a href="/measures">Maßnahmen</a> eine neue Anpasssitzung, indem Sie auf „Neu planen“ klicken und ein Datum, eine Uhrzeit und einen Ort auswählen.',
  HOW_SCHEDULE_FITTING: 'Wie plane ich eine Anprobe?',
  HOW_SCHEDULE_FITTING_ANSWER:
    'Gehen Sie zu <a href="/measures">Maßnahmen</a>, klicken Sie auf „Planen“ oder „Neu planen“ und wählen Sie ein Datum, eine Uhrzeit und einen Ort aus.',
  HOW_CHECK_STATUS: 'Wie überprüfe ich den Status meiner Bestellung?',
  HOW_CHECK_STATUS_ANSWER:
    'Gehen Sie zu <a href="/myorders">Meine Bestellungen</a>, suchen Sie nach Ihrer Bestellnummer und klicken Sie auf das „+“-Symbol, um weitere Details zu Ihrer Bestellung anzuzeigen.',
  RECEIVED_INCOMPLETE: 'Ich habe eine unvollständige Bestellung erhalten.',
  RECEIVED_INCOMPLETE_ANSWER:
    'Es ist möglich, dass Ihre Bestellung in Paketen versendet wurde. Die Bestellung wird abgeschlossen, sobald der Vorrat verfügbar ist. Nach der Sendung erhalten Sie eine E-Mail mit den Artikeln und der Sendungsverfolgungsnummer für jede Lieferung.',
  RECEIVED_INCORRECT: 'Ich habe falsche Artikel erhalten.',
  RECEIVED_INCORRECT_ANSWER:
    'Sie haben die von Ihnen ausgewählten und unterschriebenen Artikel zum Zeitpunkt der Anprobe erhalten. Wenn aufgrund unserer Logistikdienste ein falscher Artikel vorliegt, wenden Sie sich bitte an cs@wearskypro.com. \n\n Hinweis: Ihr Unternehmen akzeptiert keine Rücksendungen von Artikeln in den von Ihnen ausgewählten Größen.\n Hinweis: Für den Versand von Artikeln mit neuen Größen aus Umtauschgründen fallen Versandkosten an, die Sie tragen müssen.',
  PAYMENT_METHODS: 'Zahlungsarten.',
  PAYMENT_METHODS_ANSWER:
    'Wir akzeptieren die folgenden Zahlungsarten:\n VISA\n Geldautomat\n Paypal\n MasterCard\n American Express\n\n Wir versichern, dass Ihre Zahlung sicher und gemäß den internationalen Sicherheitsstandards für Online-Zahlungen erfolgen kann. \n Ihre Kreditkartennummer wird auf einer sicheren Seite eingegeben, die von unserer Bank gehostet wird und daher außerhalb unserer Website liegt. Während des Zahlungsvorgangs wird keine Kreditkartennummer erfasst oder ist für unsere Website sichtbar. Wir haben keinen Zugriff auf Ihre Zahlungsinformationen.\n Diese Website verwendet die Secure Sockets Layer (SSL)-Technologie, um ein möglichst sicheres Einkaufserlebnis zu bieten. Die SSL-Technologie ermöglicht die Verschlüsselung (Kodierung) vertraulicher Informationen während Ihrer Online-Transaktionen. Alle Formulare auf unserer Website sind mit der gleichen SSL-Technologie geschützt, sodass Ihre persönlichen Daten sicher bleiben.',
  REFUND_PERIOD: 'Rückerstattungszeitraum.',
  REFUND_PERIOD_ANSWER:
    'Rückerstattungen erfolgen innerhalb von 5 bis 10 Werktagen nach Erhalt der zurückgegebenen Artikel über die gleiche Zahlungsmethode, mit der Sie die Bestellung bearbeitet haben.\n\n Wenn Sie mit einer Geschenkkarte oder einer Zahlungskarte bezahlen ( virtuell oder physisch), wird die Rückerstattung auf derselben Karte gutgeschrieben. Wenn Sie zum Zeitpunkt der Rückerstattung die für die Zahlung verwendete Karte nicht mehr bei sich haben, empfehlen wir Ihnen, sich an unsere Kundendienstabteilung unter cs@wearskypro.com zu wenden.',
  INCIDENT_UNIFORM:
    'Während der Arbeit hatte ich einen Vorfall mit meiner Uniform. Kann ich es umtauschen?',
  INCIDENT_UNIFORM_ANSWER:
    'Gehen Sie zu <a href="/orders">Bestellungen</a>, „Einheitliche Vorfälle“ und erläutern Sie die Situation. Falls Ihre Beschwerde akzeptiert wird, wird eine Benachrichtigung gesendet, die besagt, dass ein neuer Artikel als Ersatz für den beschädigten Artikel zur Verfügung steht.\n Falls Ihre Beschwerde nicht akzeptiert wird, wird eine Benachrichtigung mit Angabe des Grundes gesendet.',
  GENERAL: 'Allgemein',
  PAYMENTS: 'Zahlungen',
  RETURNS_EXCHANGES: 'Rückgaben und Umtausch',
  HELPFUL: 'Hilfreich',
  NOT_HELPFUL: 'Nicht hilfreich',
  EXTRA_HELP: 'Benötigen Sie zusätzliche Hilfe?',
  DETAILS_HEADER: 'Einzelheiten',
  COMPOSITION: 'Komposition',
  COMPOSITION_ANSWER: '50% Baumwolle, 47% Polyamid, 3% Elasthan',
  WEIGHT: 'Gewicht',
  WEIGHT_ANSWER: '335grs',
  WIDTH: 'Breite',
  WIDTH_ANSWER: '160CM',
  TEXTILE: 'Textil',
  TWILL: 'Köper',
  TECH_SUBTITLE:
    'Voller Schutz und Sicherheit für Ihr intensives Arbeitsleben.',
  ABSOLUTE_COMFORT: 'Absoluter Komfort',
  ABSOLUTE_COMFORT_TEXT:
    'Skypro-Einlegesohlen verwenden ein spezielles Material mit mikrozellulärer Struktur und erstaunlichen Eigenschaften. Sie verlieren nicht an Dämpfung, sind atmungsaktiv, weich und elastisch, absorbieren Bodenstöße und schonen so die Wirbelsäule und Gelenke und bieten höhere Flexibilität für absoluten Komfort.',
  EASY_CLEAN: 'Einfache Reinigung',
  EASY_CLEAN_TEXT:
    'Durch die Verbesserung der abweisenden Eigenschaften der Oberfläche unserer Stoffe und den Einsatz sorgfältig ausgewählter Spitzentechnologie können Flecken leicht entfernt werden. Durch diese Veredelung werden die Trocknungseigenschaften des Stoffes verbessert und wir garantieren ein wesentlich langlebigeres Kleidungsstück.',
  ECOLOGIC: 'Ökologisch',
  ECOLOGIC_TEXT:
    'Gewährleistung eines minimalen Abfall-, Verpackungs- und Umweltfußabdrucks gemäß den UN-Empfehlungen, wobei Recycling und wiederverwendete Verpackungen Vorrang haben und die Verwendung nachhaltiger, erneuerbarer und ökologischer Rohstoffe gefördert wird.',
  RELATED_TITLE: 'Weitere Artikel für Sie',
  COLORS_TITLE: 'Verfügbare Farben',
  IMAGE: 'Bild',
  TECHNOLOGY: 'Technologie',
  RELATED: 'Verwandt',
  CHECKOUT: 'Kasse',
  CONFIRM_DETAILS: 'Bestätigen Sie unten Ihre Angaben',
  CONTACT: 'Kontakt',
  SEND_TO: 'Ausliefern',
  PAYMENT_METHOD: 'Zahlungsmethode',
  SECURE_ENCRYPTED: 'Alle Transaktionen sind sicher und verschlüsselt',
  CHANGE: 'Veränderung',
  CREDIT_CARD: 'Kreditkarte',
  CREDIT_CARD_TEXT:
    'Wenn Sie auf „Bestellung abschließen“ klicken, werden Sie zur Zahlungsseite weitergeleitet',
  ATM: 'Multibanco / ATM-Referenz',
  ATM_TEXT: 'Sie erhalten eine E-Mail mit den Zahlungsdetails',
  BILLING_DATA: 'Rechnungsinfo',
  INVOICE_TO: 'Rechnung an',
  COMPLETE_ORDER: 'Bestellung abschließen',
  REFUND_POLICY: 'Rückgaberecht',
  PRIVACY_POLICY: 'Datenschutz-Bestimmungen',
  TERMS_SERVICE: 'Nutzungsbedingungen',
  VOUCHER_CODE: 'Coupon code',
  APPLY: 'Sich bewerben',
  SUBTOTAL: 'Zwischensumme',
  TRANSPORTATION: 'Versand',
  TOTAL: 'Gesamt',
  NO_ITEMS: 'Sie haben keine Artikel in Ihrem Warenkorb.',
  SCROLL_DOWN: 'Runterscrollen',
  COLOR: 'FARBE',
  STATUS: 'Status',
  VALUE_TAG: 'Geschlossen',
  STATUS_TAG: 'Erneuert am:',
  ORDER_BTN: 'Komplette uniform bestellen',
  FUNCTION: 'Funktion:',
  OUTFIT_ITEMS: 'Artikel in Outfit:',
  NEXT_FITTING: 'Nächste Anprobe:',
  RESCHEDULE: 'Neu planen',
  SCHEDULE: 'Zeitplan',
  ABOUT: 'Über Skypro',
  PROHEALTH: 'ProHealth-Technologie',
  CUSTOMER: 'Kundenservice',
  FOLLOW: 'Verbinden & Folgen',
  NEWSLETTER: 'Newsletter',
  BLOG: 'Blog',
  COPYRIGHT: '©${date} SKYPRO. Alle Rechte vorbehalten.',
  ORDER: 'Auftrag',
  DATE: 'Datum',
  DELIVERY: 'Lieferung',
  NO_ORDERS: 'Keine Bestellungen gefunden',
  DELIVERY_ADDRESS: 'Lieferadresse',
  PROBLEM_CONTACT: 'Problem? Kontaktiere uns',
  ORDER_SUMMARY: 'Bestellübersicht',
  ITEMS: 'Artikel',
  POSTAGE_PACKING: 'Versand & Verpackung',
  ORDER_TOTAL: 'Gesamtbetrag der Bestellung',
  DATE_SUBMISSION: 'Datum der Einreichung',
  ESTIMATED_DELIVERY: 'Voraussichtliche Lieferung',
  PAYMENT_STATE: 'Zahlungsstatus',
  GET_REFERENCE: 'Weiter zur Zahlung',
  QUANTITY: 'Menge',
  ADD_ADDRESS: 'Neue Adresse hinzufügen',
  EDIT_ADDRESS: 'Adresse bearbeiten',
  SUBMIT: 'Einreichen',
  ADDRESS_BOOK: 'Adressbuch',
  MANAGE_ADDRESS: 'Verwalten Sie Ihre Adressen',
  CITY_REQUIRED: 'Stadt ist ein pflichtfeld',
  SCHEDULE_FITTING_TITLE: 'Planen sie die anpassung',
  SCHEDULE_FITTING_SUBTITLE:
    'Wählen Sie aus, wo Sie gemessen werden möchten',
  HELLO: 'Hallo',
  CREDITS_LEFT: 'VERBLEIBENDE CREDITS',
  OF: 'VON',
  RENEWS_ON: 'ERNEUERT AM',
  DURATION: 'DAUER',
  BACK_TO_CALENDAR: 'Zurück zum kalender',
  COMPLETE_APPOINTMENT: 'Vollständiger termin',
  EMPLOYEE: 'Mitarbeiter',
  ITEMS_OUTFIT: 'Gegenstände im Outfit',
  LAST_FITTING: 'Letzte Anpassung',
  PREVIOUS: 'Vorherige',
  NEXT: 'Nächste',
  CHOOSE_SIZE: 'Wählen Sie die Größe oder scannen Sie automatisch',
  DECLARE_MEASURES: 'Hiermit erkläre ich, dass die Messungen korrekt sind',
  INSTRUCTIONS:
    'Befolgen Sie die Anweisungen im <a href="../../files/fitting_guide.mp4" target="_blank" rel="noopener noreferrer">Tutorial-Video</a> und lesen Sie dann das <a href= "../../files/fitting_sizes_table.pdf" target="_blank" rel="noopener noreferrer">Referenztabelle</a>',
  UNIQUE_SIZE: 'EINZIGARTIGE GRÖSSE',
  SIZE: 'GRÖSSE: ',
  REF_FITTING: 'Ref-Anpassung',
  TELEPHONE: 'Telefon',
  HOUR: 'Stunde',
  SELECT: 'Wählen',
  CHECKOUT_NOW: 'Checke jetzt aus',
  CLEAR_BAG: 'Klare tasche',
  YOU_WILL_LIKE: 'Du wirst mögen',
  MONTHS: 'monate',
  ADD_BAG: 'der tasche hinzufügen',
  SELECT_QUESTION_TYPE:
    'Wenn Sie möchten, wählen Sie den Fragetyp aus und konsultieren Sie unsere FAQ:',
  FITTING_LOWERCASE: 'passend zu',
  NOT_FITTED: 'NICHT ANGEPASST',
  FITTED: 'TAILLIERT',
  NA: 'NA',
  SAVE_CHANGES: 'ÄNDERUNGEN SPEICHERN',
  BIRTH_DATE_OPTIONAL: 'Geburtsdatum (optional)',
  FIRST_NAME: 'Vorname',
  LAST_NAME: 'Nachname',
  SORT_BY: 'Sortiere Nach',
  CART_CREDIT: 'credit',
  CART_CREDITS: 'credits',
  CART_ADD_ADDRESS: 'Veränderung',
  CART_SHIPMENT_TEXT_1: 'Maximal',
  CART_SHIPMENT_TEXT_2: 'sendungen pro jahr.',
  CART_SHIPMENT_TEXT_3: 'lieferungen noch verfügbar.',
  PAYMENT_RECEIVED: 'Erhalten',
  PAYMENT_PENDING: 'Ausstehend',
  DOCUMENTS: 'Unterlagen',
  NOTES: 'Anmerkungen',
  TRACKING: 'Verfolgung',
  PARTIALLY: 'Teilweise',
  COOKIES_WARNING:
    'Wir verwenden Cookies, um Ihr Benutzererlebnis auf unserer Website zu verbessern. Wenn Sie weitersurfen, gehen wir davon aus, dass Sie damit einverstanden sind',
  COOKIES_WARNING_PRIVACY: 'Datenschutzrichtlinien',
  SINGLE_SIZE: 'EINZELGRÖSSE',
  CUSTOMER_SUPPORT_EMAIL: 'E-Mail des Kundensupports:',
  CUSTOMER_SUPPORT_PHONE:
    'Für den direkten Kundensupport, bitte verwenden Sie die Telefonnummer in Ihrem geografischen Gebiet.',
  CUSTOMER_SUPPORT_EUROPE: 'Portugal und europäische Länder',
  CUSTOMER_SUPPORT_USA: 'USA',
  CUSTOMER_SUPPORT_UNITED_KINGDOM: 'Großbritannien',
  CUSTOMER_SUPPORT_CANADA: 'Kanada',
  CUSTOMER_SUPPORT_OBS_1: 'Gesprächskosten ins',
  CUSTOMER_SUPPORT_0BS_2: 'nationale festnetz',
  NAME: 'Name',
  SUBJECT_PROBLEM_EMAIL: 'Ich habe ein Problem mit meiner Bestellung',
  ORDER_FOUND: ' bestellung gefunden',
  ORDERS_FOUND: ' bestellungen gefunden',
  PRODUCT: 'Produkt',
  CHOOSE_YOUR_SIZE: 'WÄHLEN SIE IHRE GRÖSSE',
  RECOVER_SUCCESS:
    'Ein neues Passwort wurde an die E-Mail des Benutzers gesendet',
  SIGN_UP: 'Neu im Portal?',
  SIGN_UP_LINK: 'ANMELDUNG',
  RETURN_TO_LOGIN_PAGE: 'Zurück zum Login',
  ONBOARDING: 'Onboarding',
  ONBOARDING_CONTENT_TITLE: 'Willkommen im Uniform-Portal',
  ONBOARDING_CONTENT_SUBTITLE:
    'Bitte füllen Sie unten die erforderlichen Informationen aus:',
  ACCEPT: 'Akzeptieren',
  GENDER: 'Geschlecht',
  DISTRICT: 'District',
  ADDRESS: 'Adresse',
  DAY: 'Tag',
  MONTH: 'Monat',
  YEAR: 'Jahr',
  JANUARY: 'Januar',
  FEBRUARY: 'Februar',
  MARCH: 'Marsch',
  APRIL: 'April',
  MAY: 'Mai',
  JUNE: 'Juni',
  JULY: 'Juli',
  AUGUST: 'August',
  SEPTEMBER: 'September',
  OCTOBER: 'Oktober',
  NOVEMBER: 'November',
  DECEMBER: 'Dezember',
  ERROR: 'Fehler',
  ERROR_CURRENT_PASSWORD_REQUIRED:
    'Das aktuelle Passwort ist ein Pflichtfeld',
  ERROR_PASSWORD_CONFIRMATION_DOES_NOT_MATCH:
    'Die Passwortbestätigung stimmt nicht mit dem Passwort überein',
  ERROR_PASSWORD_EQUAL_PREVIOUS:
    'Das aktuelle Passwort entspricht dem vorherigen Passwort. Bitte verwenden Sie eine andere',
  ERROR_DATA_SAVED_FAILED:
    'Die Daten konnten nicht geändert werden. Bitte versuchen Sie es erneut',
  SUCCESS: 'Erfolg',
  SUCCESS_DATA_SAVED_SUCCESSFULLY: 'Daten wurden erfolgreich aktualisiert',
  AFGHANISTAN: 'Afghanistan',
  ALAND_ISLANDS: 'Åland-Inseln',
  ALBANIA: 'Albanien',
  ALGERIA: 'Algerien',
  AMERICAN_SAMOA: 'Amerikanischen Samoa-Inseln',
  ANDORRA: 'Andorra',
  ANGOLA: 'Angola',
  ANGUILLA: 'Anguilla',
  ANTARCTICA: 'Antarktis',
  ANTIGUA_AND_BARBUDA: 'Antigua und Barbuda',
  ARGENTINA: 'Argentinien',
  ARMENIA: 'Armenien',
  ARUBA: 'Aruba',
  AUSTRALIA: 'Australien',
  AUSTRIA: 'Österreich',
  AZERBAIJAN: 'Aserbaidschan',
  BAHAMAS: 'Bahamas',
  BAHRAIN: 'Bahrain',
  BANGLADESH: 'Bangladesch',
  BARBADOS: 'Barbados',
  BELARUS: 'Weißrussland',
  BELGIUM: 'Belgien',
  BELIZE: 'Belize',
  BENIN: 'Benin',
  BERMUDA: 'Bermuda',
  BHUTAN: 'Bhutan',
  BOLIVIA: 'Bolivien',
  BOSNIA_AND_HERZEGOVINA: 'Bosnien und Herzegowina',
  BOTSWANA: 'Botswana',
  BOUVET_ISLAND: 'Bouvetinsel',
  BRAZIL: 'Brasilien',
  BRITISH_INDIAN_OCEAN_TERRITORY:
    'Britisches Territorium des Indischen Ozeans',
  BRITISH_VIRGIN_ISLANDS: 'Britische Jungferninseln',
  BRUNEI_DARUSSALAM: 'Brunei Darussalam',
  BULGARIA: 'Bulgarien',
  BURKINA_FASO: 'Burkina Faso',
  BURUNDI: 'Burundi',
  CAMBODIA: 'Kambodscha',
  CAMEROON: 'Kamerun',
  CANADA: 'Kanada',
  CANARY_ISLANDS: 'Kanarische Inseln',
  CAPE_VERDE: 'Kapgrün',
  CAYMAN_ISLANDS: 'Cayman Inseln',
  CENTRAL_AFRICAN_REPUBLIC: 'Zentralafrikanische Republik',
  CHAD: 'Tschad',
  CHANNEL_ISLANDS: 'Kanalinseln',
  CHILE: 'Chile',
  CHINA: 'China',
  CHRISTMAS_ISLAND: 'Weihnachtsinsel',
  COCOS_KEELING_ISLANDS: 'Kokosinseln (Keelinginseln)',
  COLOMBIA: 'Kolumbien',
  COMOROS: 'Komoren',
  CONGO_KINSHASA: 'Kongo (Kinshasa)',
  CONGO_BRAZZAVILLE: 'Kongo (Brazzaville)',
  COOK_ISLANDS: 'Cookinseln',
  COSTA_RICA: 'Costa Rica',
  CROATIA: 'Kroatien',
  CUBA: 'Kuba',
  CYPRUS: 'Zypern',
  CZECHIA: 'Tschechisch',
  DENMARK: 'Dänemark',
  DJIBOUTI: 'Dschibuti',
  DOMINICA: 'Dominica',
  DOMINICAN_REPUBLIC: 'Dominikanische Republik',
  ECUADOR: 'Ecuador',
  EGYPT: 'Ägypten',
  EL_SALVADOR: 'El Salvador',
  EQUATORIAL_GUINEA: 'Äquatorialguinea',
  ERITREA: 'Eritrea',
  ESTONIA: 'Estland',
  ETHIOPIA: 'Äthiopien',
  FALKLAND_ISLANDS_MALVINAS: 'Falklandinseln (Malvinas)',
  FAROE_ISLANDS: 'Färöer Inseln',
  FIJI: 'Fidschi',
  FINLAND: 'Finnland',
  FRANCE: 'Frankreich',
  FRENCH_GUIANA: 'Französisch-Guayana',
  FRENCH_POLYNESIA: 'Französisch-Polynesier',
  FRENCH_SOUTHERN_TERRITORIES: 'Südfranzösische Territorien',
  GABON: 'Gabun',
  GAMBIA: 'Gambia',
  GEORGIA: 'Georgia',
  GERMANY: 'Deutschland',
  GHANA: 'Ghana',
  GIBRALTAR: 'Gibraltar',
  GREECE: 'Griechenland',
  GREENLAND: 'Grönland',
  GRENADA: 'Granate',
  GUADELOUPE: 'Guadeloupe',
  GUAM: 'Guam',
  GUATEMALA: 'Guatemala',
  GUERNSEY: 'Guernsey',
  GUINEA: 'Guinea',
  GUINEA_BISSAU: 'Guinea-Bissau',
  GUYANA: 'Guyana',
  HAITI: 'Haiti',
  HEARD_AND_MCDONALD_ISLANDS: 'Heard- und McDonald-Inseln',
  HONDURAS: 'Honduras',
  HONG_KONG: 'Honduras',
  HUNGARY: 'Ungarn',
  ICELAND: 'Island',
  INDIA: 'Indien',
  INDONESIA: 'Indonesien',
  IRAN_ISLAMIC_REPUBLIC_OF: 'Iran (Islamische Republik Iran)',
  IRAQ: 'Irak',
  IRELAND: 'Irland',
  ISLE_OF_MAN: 'Isle of Man',
  ISRAEL: 'Israel',
  ITALY: 'Italien',
  IVORY_COAST: 'Costa do Marfim',
  JAMAICA: 'Jamaika',
  JAPAN: 'Japan',
  JERSEY: 'Jersey',
  JORDAN: 'Jordanien',
  KAZAKHSTAN: 'Kasachstan',
  KENYA: 'Kenia',
  KIRIBATI: 'Kiribati',
  KOREA_NORTH: 'Nord Korea',
  KOREA_SOUTH: 'Südkorea',
  KOSOVO: 'Kosovo',
  KUWAIT: 'Kuwait',
  KYRGYZSTAN: 'Kirgisistan',
  LAO_PDR: 'Demokratische Volksrepublik Laos',
  LATVIA: 'Lettland',
  LEBANON: 'Libanon',
  LESOTHO: 'Lesotho',
  LIBERIA: 'Liberia',
  LIBYA: 'Libyen',
  LIECHTENSTEIN: 'Liechtenstein',
  LITHUANIA: 'Litauen',
  LUXEMBOURG: 'Luxemburg',
  MACAO: 'Macau',
  MADAGASCAR: 'Madagaskar',
  MALAWI: 'Malawi',
  MALAYSIA: 'Malaysia',
  MALDIVES: 'Malediven',
  MALI: 'Mali',
  MALTA: 'Malta',
  MARSHALL_ISLANDS: 'Marshallinseln',
  MARTINIQUE: 'Martinique',
  MAURITANIA: 'Mauretanien',
  MAURITIUS: 'Mauritius',
  MAYOTTE: 'Mayotte',
  MEXICO: 'Mexiko',
  MICRONESIA_FEDERATED_STATES_OF: 'Mikronesien, Föderierte Staaten von',
  MOLDOVA: 'Moldau',
  MONACO: 'Monaco',
  MONGOLIA: 'Mongolei',
  MONTENEGRO: 'Montenegro',
  MONTSERRAT: 'Montserrat',
  MOROCCO: 'Marokko',
  MOZAMBIQUE: 'Mosambik',
  MYANMAR: 'Myanmar',
  NAMIBIA: 'Namibia',
  NAURU: 'Nauru',
  NEPAL: 'Nepal',
  NETHERLANDS: 'Niederlande',
  NETHERLANDS_ANTILLES: 'Niederländische Antillen',
  NEW_CALEDONIA: 'Neu-Kaledonien',
  NEW_ZEALAND: 'Neuseeland',
  NICARAGUA: 'Nicaragua',
  NIGER: 'Niger',
  NIGERIA: 'Nigeria',
  NIUE: 'Niue',
  NORFOLK_ISLAND: 'Norfolkinsel',
  NORTH_MACEDONIA: 'Nordmazedonien',
  NORTHERN_MARIANA_ISLANDS: 'Nördliche Marianneninseln',
  NORWAY: 'Norwegen',
  OMAN: 'Oman',
  PAKISTAN: 'Pakistan',
  PALAU: 'Palau',
  PALESTINIAN_TERRITORY: 'Palästinensisches Gebiet',
  PANAMA: 'Panama',
  PAPUA_NEW_GUINEA: 'Papua Neu-Guinea',
  PARAGUAY: 'Paraguay',
  PERU: 'Peru',
  PHILIPPINES: 'Philippinen',
  PITCAIRN: 'Picarnia-Inseln',
  POLAND: 'Polen',
  PORTUGAL: 'Portugal',
  PUERTO_RICO: 'Puerto Rico',
  QATAR: 'Katar',
  REUNION_ISLAND: 'Insel La Réunion',
  ROMANIA: 'Rumänien',
  RUSSIAN_FEDERATION: 'Russische Föderation',
  RWANDA: 'Ruanda',
  SAINT_BARTHOLOMEW: 'Heiliger Bartholomäus',
  SAINT_HELENA: 'Heilige Helena',
  SAINT_KITTS_AND_NEVIS: 'St. Kitts und Nevis',
  SAINT_LUCIA: 'St. Lucia',
  SAINT_PIERRE_AND_MIQUELON: 'Saint-Pierre und Miquelon',
  SAINT_VINCENT_AND_GRENADINES: 'St. Vincent und die Grenadinen',
  SAINT_MARTIN_FRENCH_PART: 'Saint-Martin (französischer Teil)',
  SAMOA: 'Samoa',
  SAN_MARINO: 'San Marino',
  SAO_TOME_AND_PRINCIPE: 'São Tomé und Príncipe',
  SAUDI_ARABIA: 'Saudi-Arabien',
  SENEGAL: 'Senegal',
  SERBIA: 'Serbien',
  SEYCHELLES: 'Seychellen',
  SIERRA_LEONE: 'Sierra Leone',
  SINGAPORE: 'Singapur',
  SLOVAKIA: 'Slowakei',
  SLOVENIA: 'Slowenien',
  SOLOMON_ISLANDS: 'Salomon-Inseln',
  SOMALIA: 'Somalia',
  SOUTH_AFRICA: 'Südafrika',
  SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS:
    'Süd-Georgien und die südlichen Sandwich-Inseln',
  SOUTH_SUDAN: 'Südsudan',
  SPAIN: 'Spanien',
  SRI_LANKA: 'Sri Lanka',
  SUDAN: 'Sudan',
  SURINAME: 'Surinam',
  SVALBARD_AND_JAN_MAYEN_ISLANDS: 'Spitzbergen und Jan-Mayen-Inseln',
  SWAZILAND: 'Swasiland',
  SWEDEN: 'Schweden',
  SWITZERLAND: 'Schweiz',
  SYRIAN_ARAB_REPUBLIC_SYRIA: 'Arabische Republik Syrien (Syrien)',
  TAIWAN_REPUBLIC_OF_CHINA: 'Taiwan, Republik China',
  TAJIKISTAN: 'Tadschikistan',
  TANZANIA_UNITED_REPUBLIC_OF: 'Tansania, Vereinigte Republik',
  THAILAND: 'Thailand',
  TIMOR_LESTE: 'Osttimor',
  TOGO: 'Togo',
  TOKELAU: 'Tokelau',
  TONGA: 'Tonga',
  TRINIDAD_AND_TOBAGO: 'Trinidad und Tobago',
  TUNISIA: 'Tunesien',
  TURKEY: 'Türkei',
  TURKMENISTAN: 'Turkmenistan',
  TURKS_AND_CAICOS_ISLANDS: 'Turks- und Caicosinseln',
  TUVALU: 'Tuvalu',
  UGANDA: 'Uganda',
  UKRAINE: 'Ukraine',
  UNITED_ARAB_EMIRATES: 'Vereinigte Arabische Emirate',
  UNITED_KINGDOM: 'Vereinigtes Königreich',
  UNITED_STATES_OF_AMERICA: 'USA',
  URUGUAY: 'Uruguay',
  US_MINOR_OUTLYING_ISLANDS: 'Kleinere vorgelagerte Inseln der USA',
  UZBEKISTAN: 'Usbekistan',
  VANUATU: 'Vanuatu',
  VATICAN_CITY: 'Vatikanstadt',
  VENEZUELA_BOLIVARIAN_REPUBLIC: 'Venezuela (Bolivarische Republik)',
  VIETNAM: 'Vietnam',
  VIRGIN_ISLANDS_US: 'Jungferninseln, USA',
  WALLIS_AND_FUTUNA_ISLANDS: 'Wallis- und Futuna-Inseln',
  WESTERN_SAHARA: 'Westsahara',
  YEMEN: 'Jemen',
  ZAMBIA: 'Sambia',
  ZIMBABWE: 'Zimbabwe',
  SUCCESS_ADDRESS_SAVED_SUCCESSFULLY: 'Adresse erfolgreich gespeichert',
  ORDER_REQUEST_WAS_COMPLETED:
    'Ihre Bestellung wurde erfolgreich abgeschlossen',
  ORDER_REQUEST_WAS_RECEIVED: 'Ihre Bestellanfrage wurde empfangen',
  ORDER_NUMBER: 'Bestellnummer',
  ORDER_COMPLETED_DETAILS_1:
    'Eine E-Mail mit den Details zu Ihrer Bestellung wurde an die von Ihnen angegebene E-Mail-Adresse gesendet.',
  ORDER_COMPLETED_DETAILS_2:
    'Bitte bewahren Sie es für Ihre Unterlagen auf.',
  ORDER_COMPLETED_DETAILS_3:
    'Sie können die Seite Mein Bestellungen jederzeit besuchen, um den Status Ihrer Bestellung zu überprüfen.',
  ORDER_RECEIVED_DETAILS_1:
    'Die Bestelldaten wurden an die angegebene E-Mail-Adresse gesendet.',
  ORDER_RECEIVED_DETAILS_2:
    'Warten Sie nun bitte auf die Genehmigung Ihres Unternehmens.',
  ORDER_RECEIVED_DETAILS_3:
    'Sie können den Fortschritt jederzeit im Bereich „Meine Bestellungen“ überprüfen.',
  CONTINUE_SHOPPING: 'Weiter einkaufen',
  BUTTON_MY_ORDERS: 'Meine Bestellungen',
  THANK_YOU: 'Danke',
  FITTING_SCHEDULE_NON_EXISTANT:
    'Leider sind keine Slots verfügbar, um Ihre Anpassungssitzung zu planen. Bitte wenden Sie sich an Ihren Vorgesetzten.',
  PROCEED_TO_MEASURES: 'Weiter zu Maßnahmen',
  LAYOUT_VIEW: 'Layoutansicht',
  IMAGE_VIEW: 'Bildansicht',
  CHECK_TERMS_SERVICE:
    'Sie müssen erklären, dass die Messungen korrekt sind',
  REJECT: 'Ablehnen',
  PRIVACY_POLICY_CHECK:
    'Ich erkläre, dass ich die Datenschutzrichtlinie von SKYPRO gelesen und zur Kenntnis genommen habe',
  PRIVACY_POLICY_CHECK_UNVALID:
    'Um dieses Portal nutzen zu können, müssen Sie die Datenschutzrichtlinie von SKYPRO akzeptieren'
}
